import React from "react";
import cloudimg from "../../new_Images/OtherComponents/48.png";
import cloudimg3 from "../images/cloud4.png";
import aws from "../images/aws.png";
import azure from "../images/azure.png";
import gcp from "../images/gcp.jpg";

import "../../App.css";

function CloudServices() {
  return (
    <>
      <div className="">
        <img
          src={cloudimg}
          className="img-fluid d-block mx-auto homebanner"
          alt="responsiveImage"
        />
      </div>

      {/* Cloud Services */}

      <div className="container">
        <div className="row justify-content-center">
          <p className="mbr-text mbr-fonts-style display-7 mt-5">
            {/* <br /> */}
            <i>
              <p>
                Cloud services, hosted by third-party providers and accessed via
                the internet, enable cloud-native applications and flexible
                work. Our web development team creates tailored websites and
                applications. Businesses no longer need to purchase and manage
                hardware, reducing costs and complexity.{" "}
              </p>
            </i>
          </p>
          <br />
        </div>
      </div>

      {/* </div> */}

      <div className="container">
        <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
          <b>
            <i>Benefits of Cloud Services</i>
          </b>
        </h4>
        <p>
          <i>
            Cloud providers create online platforms for code development and app
            running, distinct from abstracting computer capabilities from
            hardware in cloud infrastructure provision.
          </i>
        </p>
      </div>

      {/*Managing Public Clouds  */}

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <br />
            <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b>
                <i>Managing Public Clouds</i>
              </b>
            </h4>

            <p>
              <i>
                We manage major public clouds like Microsoft Azure, Google
                Cloud, and AWS, optimizing and effectively utilizing their
                infrastructure, platforms, and services for our clients.
              </i>
            </p>
            <br />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center ">
            <div className="row">
              <div className="col-4 col-sm-4">
                <div className="Cloudimage text-center">
                  <img
                    src={azure}
                    className="rounded mx-auto d-block"
                    alt="responsiveImage"
                    style={{ width: "100px", height: "100px" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.filter = "grayscale(0%)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.filter = "grayscale(100%)")
                    }
                  />
                </div>
              </div>
              <div className="col-4 col-sm-4">
                <div className="Cloudimage text-center">
                  <img
                    src={aws}
                    className="rounded mx-auto d-block"
                    alt="responsiveImage"
                    style={{ width: "100px", height: "100px" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.filter = "grayscale(0%)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.filter = "grayscale(100%)")
                    }
                  />
                </div>
              </div>
              <div className="col-4 col-sm-4">
                <div className="Cloudimage text-center">
                  <img
                    src={gcp}
                    className="rounded mx-auto d-block"
                    alt="responsiveImage"
                    style={{ width: "100px", height: "100px" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.filter = "grayscale(0%)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.filter = "grayscale(100%)")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Managing private cloud */}

      <div className="container">
        <div className="row cloudmanage">
          <div className="col-md-6">
            <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b>
                <i>Managing Private Cloud</i>
              </b>
            </h4>

            <p>
              <i>
                Managed private cloud providers offer a private cloud that's
                deployed and managed by third parties. Our cloud delivery
                enhances security, cost-effectiveness, storage flexibility,
                automatic updates, quick deployment, and overall efficiency.
              </i>
              <br />
              {/* <br />

              <h5>
                <b>Advantages –</b>
              </h5>
              <i>
                
                
                  <li>Security</li>
                
                
                  <li>Cost-Effectiveness</li>
                
                
                  <li>Storage Flexibility</li>
                
                
                  <li>Automatic Update</li>
                
                
                  <li>Quick Deployment</li>
                
                
                  <li>Efficiency</li>
                
              </i> */}
            </p>
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center">
            <br />

            <img
              src={cloudimg3}
              alt="responsiveImage"
              className="img-fluid"
              style={{ width: "390px", height: "320px" }}
            />
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

export default CloudServices;
