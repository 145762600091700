import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import {Switch} from 'react-router-dom'
import Home from "./components/Pages/Home";
import Navbar from "./components/inc/Navbar";
import Industry from "./components/Pages/Industry";
import Careers from "./components/Pages/Careers";
import Clients from "./components/Pages/Clients";
import ContactUs from "./components/Pages/ContactUs";
import Footer from "./components/inc/Footer";
import About from "./components/Pages/About";
import WebDevelopment from "./components/Services/WebDevelopment";
import DatabaseServices from "./components/Services/DatabaseServices";
import CloudServices from "./components/Services/CloudServices";
import AdditionalServices from "./components/Services/AdditionalServices";
import "react-toastify/dist/ReactToastify.css";
import ThankYouPage from "./components/Pages/ThankYouPage";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Industry" element={<Industry />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Clients" element={<Clients />} />
        <Route path="/ContactUsForm" element={<ContactUs />} />
        <Route path="/About" element={<About />} />
        <Route path="/WebDevelopment" element={<WebDevelopment />} />
        <Route path="/DatabaseServices" element={<DatabaseServices />} />
        <Route path="/CloudServices" element={<CloudServices />} />
        <Route path="/AdditionalServices" element={<AdditionalServices />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
