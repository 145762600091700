import React, { useState } from "react";
import thankimage from "../images/thankyou image.png";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import send from "../images/reshot-icon-send-message-RTY3EQ7USM.png";
import "./Thankyou.css";

function ThankYouPage() {
  const [showThankImage, setShowThankImage] = useState(false);
  const navigate = useNavigate();

  return (
    /* From Uiverse.io by 00Kubi */
    <div className="thankYouCard">
      <img src={send} style={{ width: "40%" }} />
      <p className="cookieHeading">Thank You !</p>
      <p className="cookieDescription">
        We got your request. We are working on your request. <br />
        {/* <a href="#">Read cookies policies</a>. */}
      </p>

      <div className="buttonContainer">
        <button
          className="acceptButton"
          onClick={() => {
            navigate("/");
          }}>
          Go Back
        </button>
        {/* <button className="declineButton">Decline</button> */}
      </div>
    </div>
  );
}
export default ThankYouPage;
