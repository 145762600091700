import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../inc/navbarcssFile.css";
import ClotaLogoHD from "../images/ClotaHDLogoRound.png";
import { MdArrowDropDownCircle, MdMiscellaneousServices, MdOutlineWork, MdSupportAgent, MdWeb } from "react-icons/md";
import { RiHome4Fill } from "react-icons/ri";
import { IoPeopleSharp } from "react-icons/io5";
import { FcAbout } from "react-icons/fc";
import { PiBuildingsFill } from "react-icons/pi";
import { FaBlogger,FaBloggerB  } from "react-icons/fa";

function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleLinkClick = () => {
    closeMobileMenu();
  };

  const [scrolling, setScrolling] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  
  

  const handleRefresh = () => {
    // Scroll to the top when the page is refreshed
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  window.addEventListener("beforeunload", handleRefresh);


  // per page scroll to top position 
  useEffect(() => {
    const handleScroll = () => {
      
      const scrollPosition = window.scrollY;
      const shouldFixNavbar = scrollPosition > 100;

      setScrolling(shouldFixNavbar);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <nav className="fixed-top">
        <div className="wrapper">
          <div className="logo" onClick={scrollToTop}>
            <img src={ClotaLogoHD} className="img-fluid" alt="clotaLogo" />

            {/* <Link to="/">Clota Technology</Link> */}
          </div>
          <input
            type="checkbox"
            name="slider"
            id="menu-btn"
            checked={mobileMenuOpen}
            onChange={toggleMobileMenu}
          />
          <input
            type="checkbox"
            name="slider"
            id="close-btn"
            checked={!mobileMenuOpen}
            onChange={toggleMobileMenu}
          />
          <ul className={`nav-links ${mobileMenuOpen ? "mobile-active" : ""}`}>
            <label for="close-btn" className="btn close-btn">
              <i className="fas fa-times"></i>
            </label>
            <li>
              <RiHome4Fill style={{ marginTop: "-5px" }} />
              <Link
                to="/"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
                className="navbarLinks">
                Home
              </Link>
            </li>

            <li>
              <MdMiscellaneousServices style={{ marginTop: "-5px" }} />
              <a href="" className="desktop-item ">
                Services
                <MdArrowDropDownCircle className="ml-2" />
              </a>
              <input type="checkbox" id="showDrop" />
              <label
                for="showDrop"
                className="mobile-item dropdownNavBarParent">
                Services <MdArrowDropDownCircle />
              </label>
              <ul className="drop-menu nav-drop-menu">
                <li>
                  <Link
                    to="/WebDevelopment"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                    className="dropdownNavBar">
                    Web Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/DatabaseServices"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                    className="dropdownNavBar">
                    Database Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/CloudServices"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                    className="dropdownNavBar">
                    Cloud Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/AdditionalServices"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                    className="dropdownNavBar">
                    Additional Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://helloems.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      handleLinkClick();
                      scrollToTop();
                    }}
                    className="dropdownNavBar">
                    HRMS Solution
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <PiBuildingsFill style={{ marginTop: "-5px" }} />
              <Link
                to="/Industry"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
                className="navbarLinks">
                Industry
              </Link>
            </li>
            <li>
              <MdOutlineWork style={{ marginTop: "-5px" }} />
              <Link
                to="/Careers"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
                className="navbarLinks">
                Careers
              </Link>
            </li>
            <li>
              <IoPeopleSharp style={{ marginTop: "-5px" }} />
              <Link
                to="/Clients"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
                className="navbarLinks">
                Clients
              </Link>
            </li>
            <li>
              <FaBloggerB className="" style={{ marginTop: "-5px" }} />
              <Link
                to="https://clotat.blogspot.com/"
                target="_newtab"
                className="navbarLinks">
                Blog
              </Link>
            </li>
            <li>
              <FcAbout />
              <Link
                to="/About"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
                className="navbarLinks">
                About Us
              </Link>
            </li>
            <li>
              <MdSupportAgent />
              <Link
                to="/ContactUsForm"
                onClick={() => {
                  handleLinkClick();
                  scrollToTop();
                }}
                className="navbarLinks">
                Contact Us
              </Link>
            </li>
          </ul>
          <label for="menu-btn" className="btn menu-btn">
            <i className="fas fa-bars"></i>
          </label>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
