import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoCall, IoMail, IoShieldCheckmarkSharp } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { TbWorldWww } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
function CommanComponentContact() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [contactName, setContactName] = useState(null);
  const [contactEmail, setContactEmail] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [otherSubject, setOtherSubject] = useState("");

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const subjectOptions = [
    { label: "Web Development", value: "Web Development" },
    { label: "Database Service", value: "Database Service" },
    { label: "Cloud Service", value: "Cloud Service" },
    { label: "Additional Service", value: "Additional Service" },
    { label: "Other", value: "Other" },
  ];

  const handleSubjectChange = (e) => {
    const value = e.value;
    setSelectedOption(value);
    if (value !== "Other") {
      setContactSubject(value);
      setErrors({ ...errors, contactSubject: "" });
    } else {
      setContactSubject("");
    }
  };
  const handleOtherSubjectChange = (e) => {
    const value = e.target.value;
    setOtherSubject(value);
    setContactSubject(value);
    if (value.length > 100) {
      setErrors({
        ...errors,
        contactSubject: "Cannot exceed 100 characters",
      });
    } else {
      setErrors({ ...errors, contactSubject: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error("Please fix the errors in the form.");
      return;
    }

    try {
      const response = await fetch("/saveContactUsEnquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: contactName,
          emailID: contactEmail,
          subject: contactSubject,
          message: contactMessage,
          mobileNumber: contactMobile,
        }),
      });

      if (response.status === 200) {
        toast.success("Details Added successfully!");
        setContactName("");
        setContactEmail("");
        setContactSubject("");
        setContactMessage("");
        navigate("/thankyou");
        scrollToTop();
        setTimeout(() => {
          navigate("/");
        }, 10000);
      } else {
        toast.error("Some error occurred.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const validate = () => {
    const errors = {};

    if (!contactName.match(/^[A-Za-z\s]{3,25}$/)) {
      errors.contactName =
        "Name must be 3-25 characters long and contain only alphabets and spaces.";
    }

    if (
      !contactEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      errors.contactEmail = "Please enter a valid email address.";
    }

    if (!contactSubject.match(/^[A-Za-z\s]{3,100}$/)) {
      errors.contactSubject =
        "Subject must be 3-100 characters long and contain only alphabets and spaces.";
    }

    if (contactMessage.length < 3 || contactMessage.length > 300) {
      errors.contactMessage = "Message must be 3-300 characters long.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mt-4 costom-clota-card">
          <h1
            className="Text-Gradient"
            style={{
              textAlign: "center",
              fontFamily: "rockwell",
            }}>
            <b> Let's Talk?</b>
          </h1>
          {/* <br /> */}

          <div className="card-body Home-Form">
            <h5 className="card-title text-center">Enquiry</h5>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Your Name
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.contactName ? "is-invalid" : ""
                  }`}
                  id="name"
                  name="name"
                  value={contactName}
                  onChange={(e) => {
                    setContactName(e.target.value);
                    if (e.target.value.length > 25) {
                      setErrors({
                        ...errors,
                        contactName: "Cannot exceed 25 characters",
                      });
                    } else {
                      setErrors({ ...errors, contactName: "" });
                    }
                  }}
                  pattern="[A-Za-z\s]+"
                  title="Please enter only alphabets and spaces"
                  required
                />
                {errors.contactName && (
                  <div className="invalid-feedback" style={{ color: "red" }}>
                    {errors.contactName}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Your Email
                </label>
                <input
                  type="email"
                  className={`form-control ${
                    errors.contactEmail ? "is-invalid" : ""
                  }`}
                  id="email"
                  name="email"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                  title="Please enter a valid email address"
                  required
                />
                {errors.contactEmail && (
                  <div className="invalid-feedback">{errors.contactEmail}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="subject" className="form-label">
                  Enquiry About
                </label>
                <Dropdown
                  value={selectedOption}
                  options={subjectOptions}
                  onChange={handleSubjectChange}
                  placeholder="Select an option"
                  className={` ${errors.contactSubject ? "is-invalid" : ""}`}
                  panelClassName="dropdown-panel-custom"
                />
                <br></br>
                {selectedOption === "Other" && (
                  <input
                    type="text"
                    className={`form-control ${
                      errors.contactSubject ? "is-invalid" : ""
                    }`}
                    id="subject"
                    name="subject"
                    value={otherSubject}
                    onChange={handleOtherSubjectChange}
                    pattern="[A-Za-z\s]+"
                    title="Please enter only alphabets and spaces"
                    required
                  />
                )}
                {errors.contactSubject && (
                  <div className="invalid-feedback" style={{ color: "red" }}>
                    {errors.contactSubject}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Your Mobile No
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.contactMessage ? "is-invalid" : ""
                  }`}
                  id="message"
                  name="message"
                  value={contactMobile}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onChange={(e) => {
                    const onlyNumbers = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    setContactMobile(onlyNumbers.substring(0, 10)); // Limit to 10 digits
                    if (onlyNumbers.length > 10) {
                      setErrors({
                        ...errors,
                        contactMobile: "Cannot exceed 10 digits",
                      });
                    } else {
                      setErrors({ ...errors, contactMobile: "" });
                    }
                  }}
                  required
                />
                {errors.contactMobile && (
                  <div className="invalid-feedback" style={{ color: "red" }}>
                    {errors.contactMobile}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Your Message
                </label>
                <textarea
                  className={`form-control ${
                    errors.contactMessage ? "is-invalid" : ""
                  }`}
                  id="message"
                  name="message"
                  value={contactMessage}
                  onChange={(e) => {
                    setContactMessage(e.target.value);
                    if (e.target.value.length > 300) {
                      setErrors({
                        ...errors,
                        contactMessage: "Cannot exceed 300 characters",
                      });
                    } else {
                      setErrors({ ...errors, contactMessage: "" });
                    }
                  }}
                  required
                />
                {errors.contactMessage && (
                  <div className="invalid-feedback" style={{ color: "red" }}>
                    {errors.contactMessage}
                  </div>
                )}
              </div>

              <div className="text-center homesumbmit">
                <button
                  type="submit"
                  className="btn-styles btn btn-info w-50"
                  disabled={
                    !contactName ||
                    !contactEmail ||
                    !contactSubject ||
                    !contactMobile ||
                    !contactMessage
                  }>
                  Submit
                </button>
              </div>
              <ToastContainer
                position="top-center"
                theme="colored"
                draggable
                pauseOnHover
                closeOnClick
              />
            </form>
          </div>
          <br />
        </div>

        <div className="col-md-6 mt-4 ">
          <div className="text-center" style={{ fontFamily: "rockwell" }}>
            <h1 className="Text-Gradient">
              <b>Reach Us Directly At</b>
            </h1>
          </div>

          <div className="card  w-100 w-md-50 mx-auto mt-4 ReachDirectly">
            {/* "card bg-black w-100 w-md-75 mx-auto mt-4" */}
            <div className="card-body contactbox">
              {/* Row 1 */}
              <div className="row mb-3">
                <div className="col-2 col-md-1 contact-icon-parent">
                  <TbWorldWww className="contact-icon" />
                </div>
                <div className="col-10 col-md-11 d-flex align-items-center">
                  <Link
                    to="https://www.clotatech.com/"
                    style={{
                      // color: "white",
                      fontSize: "14px",
                      textDecoration: "none",
                    }}>
                    <div className="weblink">
                      <span style={{ fontFamily: "rockwell" }}>
                        WWW.CLOTATECH.COM
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              <hr />

              {/* Row 2 */}
              <div className="row mb-3">
                <div className="col-2 col-md-1 contact-icon-parent">
                  <IoMail className="contact-icon" />
                </div>
                <div className="col-10 col-md-11 d-flex align-items-center">
                  <Link
                    to="mailto:support@clotatechnology.com"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                    }}>
                    support@clotatechnology.com
                  </Link>
                </div>
              </div>
              <hr />

              {/* Row 3 */}
              <div className="row mb-3">
                <div className="col-2 col-md-1 contact-icon-parent">
                  <IoCall className="contact-icon" />
                </div>
                <div className="col-10 col-md-11 d-flex align-items-center">
                  <Link
                    to="tel:+91 9490384177"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                    }}>
                    +91 9490384177
                  </Link>
                  ,
                  <Link
                    to="tel:+91 7569192527"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                    }}>
                    +91 7569192527
                  </Link>
                  ,
                  <Link
                    to="tel:+91 9573706658"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                    }}>
                    +91 9573706658
                  </Link>
                </div>
              </div>
              <hr />

              {/* Row 4 */}
              <div className="row mb-3">
                <div className="col-2 col-md-1 contact-icon-parent">
                  <FaLocationDot className="contact-icon" />
                </div>
                <div className="col-10 col-md-11">
                  <Link
                    to="https://www.google.com/maps/place/%E0%A4%AA%E0%A4%A6%E0%A5%8D%E0%A4%AE%E0%A4%9C%E0%A4%BE+%E0%A4%AA%E0%A5%88%E0%A4%B0%E0%A4%BE%E0%A4%A1%E0%A4%BE%E0%A4%87%E0%A4%9C/@17.5169463,78.3952708,15z/data=!4m6!3m5!1s0x3bcb8f1d904a1cbd:0x4ab93d4e4aa56850!8m2!3d17.5167738!4d78.3953682!16s%2Fg%2F11hdt52xhd?entry=ttu"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                    }}
                    target="_blank"
                    rel="noopener noreferrer">
                    401 Plot # 103, Shilpa Paradise, Pragathi Nagar Hyderabad,
                    Telangana, India 500090
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommanComponentContact;
