import React from "react";
import Additionalimg1 from "../../new_Images/OtherComponents/49.png";
import Additionalimg2 from "../../new_Images/OtherComponents/Software Tool Development.png";
import Additionalimg4 from "../images/softtt.png";
import Additionalimg5 from "../../new_Images/OtherComponents/Bug Found.png";
import "../../App.css";

import Addimg1 from "../images/Addimg1N.jpg";
import Addimg2 from "../images/azure.png";
import Addimg3 from "../images/Addimg3.png";
import Addimg4 from "../images/Addpostman.png";
import Addimg5 from "../images/addjira.jpg";
import Addimg6 from "../images/Addimg6.png";

function AdditionalServices() {
  return (
    <>
      <div className="">
        <img
          src={Additionalimg1}
          className="img-fluid d-block mx-auto homebanner"
          alt="Descriptionofimage"
        />
      </div>

      {/* Heading paragraph */}
      <div className="container">
        <h2 className="head-1 AdditionalSrvcsHeading">
          <br />
          <hr></hr>
          <div
            className="Text-Gradient "
            style={{
              textAlign: "center",
              fontFamily: "rockwell",
            }}>
            <b>
              We provide a diverse range of additional services to meet specific
              needs and challenges.
            </b>
          </div>
          <hr></hr>
        </h2>
      </div>

      {/* Software tool development */}

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b>
                <i>Software Tool Development</i>
              </b>
            </h4>
            {/* <br/> */}

            <i>
              <p>
                Software tools optimize business processes, document
                development, and improve project productivity. Our software tool
                development approach focuses on meeting company expectations,
                tool effectiveness, integration, choosing the right environment,
                and minimizing the learning curve.
              </p>

              {/* <p>
                  The use of these technologies in the software development
                  process will result in more productive projects.
                </p>

                <p>
                  A developer can simply maintain the project's workflow by
                  using development tools.
                </p> */}
            </i>

            {/* <b>Our Approach in Software Tool Development</b>
              <p>
                <i>
                  <li>Company expectations</li>
                  <li>Tool effectiveness</li>
                  <li>Integration of one tool with another</li>
                  <li>Choosing the Right Environment</li>
                  <li>The learning curve</li>
                </i>
              </p> */}
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img
              src={Additionalimg2}
              alt="responsiveImage"
              className=" img-fluid"
              style={{ width: "420px", height: "300px" }}
              // rounded-circle
              onMouseOver={(e) =>
                (e.currentTarget.style.filter = "grayscale(0%)")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.filter = "grayscale(100%)")
              }
            />
          </div>
        </div>
      </div>

      {/* Heading paragraph */}
      <div className="container">
        <h2 className="head-1">
          <br />
          <hr></hr>
          <div
            className="Text-Gradient"
            style={{
              textAlign: "center",
              fontFamily: "rockwell",
            }}>
            <h1>
              <b>Using Best Software Development Tools</b>
            </h1>
          </div>
          <hr></hr>
        </h2>
      </div>
      <br />

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Addimg1}
                className="card-img-top img-fluid"
                alt="responsiveImage"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Addimg2}
                className="card-img-top img-fluid"
                alt="responsiveImage"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Addimg3}
                className="card-img-top img-fluid"
                alt="responsiveImage"
                style={{
                  width: "80%",
                  height: "80%",
                  marginLeft: "10%",
                  marginTop: "5%",
                }}
              />
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Addimg4}
                className="card-img-top img-fluid"
                alt="responsiveImage"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Addimg5}
                className="card-img-top img-fluid"
                alt="responsiveImage"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div
              className="card"
              style={{ height: "150px", overflow: "hidden" }}>
              <img
                src={Addimg6}
                className="card-img-top img-fluid"
                alt="responsiveImage"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <br/> */}

      {/* Software Support and Testing */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-center ">
            <br />

            <img
              src={Additionalimg4}
              alt="responsiveImage"
              className=""
              style={{ width: "350px", height: "270px" }}
            />

            <br />
          </div>
          <div className="col-md-6">
            <br />
            <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b>
                <i>Software Support and Testing</i>
              </b>
            </h4>
            {/* <br/> */}
            <i>
              <p>
                Software, being developed by humans, can't be expected to be
                defect-free, especially with millions of lines of code. Defects
                arise from unclear requirements, programming errors, complex
                system design, poor documentation, and challenges in managing
                software design changes.
              </p>
              <p>
                We Overcome these Problems by Multiple testing and using testing
                tools.
              </p>
            </i>
          </div>
        </div>
      </div>
      <br />

      <div className="text-center">
        <img
          src={Additionalimg5}
          alt="responsiveImage"
          className="img-fluid"
          style={{ maxWidth: "50%", height: "auto" }}
        />
      </div>

      {/*  Our Software Support*/}

      <div className="container">
        <div className="col-md-12 d-flex align-items-center">
          <div className="HomeMission">
            <h4 className="Text-Gradient" style={{ fontFamily: "rockwell" }}>
              <b>
                <i>Our Software Support</i>
              </b>
            </h4>

            <p className="mbr-text mbr-fonts-style display-7">
              <i>
                <p>
                  We provide crucial after-sales support, ensuring efficient
                  issue resolution. Our software support promptly handles
                  inquiries, updates, and bug fixes for optimal usage. With our
                  Live Agent, you get quick problem resolution and software
                  updates.
                </p>
              </i>
            </p>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdditionalServices;
