import React from "react";
import Introduction1 from "../../new_Images/OtherComponents/43.png";
import youroffer1 from "../images/softtt.png";
import youroffer2 from "../images/dbb-1.png";
import youroffer3 from "../images/cloud.png";
import youroffer4 from "../images/ADDITIONAL SERVICE.png";
import aboutlastpic from "../images/teamwork-g492fd4394-1920.jpg";
import bottomBanner from "../../new_Images/OtherComponents/teamwork-g492fd4394-1920.jpg";
import { Link } from "react-router-dom";
import "../../App.css";

function About() {
  const backgroundImglast = {
    backgroundImage: `url(${aboutlastpic})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh",
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        <img
          src={Introduction1}
          className="img-fluid d-block mx-auto homebanner"
          alt="ResponsiveImage"
        />
      </div>
      <br />
      <br />

      {/* About Us */}

      <div className="container">
        <div className="col-md-12 d-flex align-items-center">
          <div className="HomeMission">
            <div className="mbr-text mbr-fonts-style display-7">
              <i>
                <p>
                  At CLOTA Technology, we provide innovative solutions in
                  database technologies, web development, cloud maintenance, and
                  software support to help businesses achieve their goals.
                </p>
                <p>
                  Our services include expert SQL and NoSQL database design,
                  implementation, and maintenance with certified DBAs boasting
                  15+ years of experience. We specialize in custom website and
                  app creation, cloud services for migrations, monitoring, and
                  security, as well as custom software tools for business
                  automation. Our support and testing services ensure system
                  stability and efficiency.
                </p>
              </i>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3 text-center OurOffer">
        <h1
          style={{ fontFamily: "rockwell" }}
          className="mt-4 mb-5 Text-Gradient">
          <b>Our Offering</b>
        </h1>
        <div className="row">
          <div className="col-md-3 mb-4 d-flex">
            <br />
            <div className="card flex-fill card-hover-effect">
              <img
                src={youroffer1}
                className="card-img-top"
                alt="Image 1"
                style={{ height: "200px" }}
              />
              <div className="card-body text-center ">
                <h5 className="card-title">Web Development</h5>
                <p className="card-text">
                  We design and construct websites that are effective business
                  channels that interact with the target audience, using elegant
                  aesthetics and out-of-the-box design concepts. Our websites
                  are simple, straightforward and tailored to particular
                  requirements.
                </p>
                <div className="d-flex justify-content-center align-items-center webbtn">
                  <Link
                    to="/WebDevelopment"
                    className="btn-styles btn btn-info"
                    onClick={scrollToTop}>
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-4 d-flex">
            <br />
            <div className="card flex-fill card-hover-effect">
              <img
                src={youroffer3}
                className="card-img-top"
                alt="Image 3"
                style={{ height: "200px" }}
              />
              <div className="card-body text-center">
                <h5 className="card-title">Cloud Services</h5>
                <p className="card-text">
                  The cloud offers a varity of IT services, including servers,
                  database, software, virtual storage and networking. Our Cloud
                  Services acts as a virtual plateform that allows you to store
                  and access your data over the internet without any
                  restictions.
                </p>
                <br />
                <div className="d-flex justify-content-center align-items-center cloudbtn">
                  <br />
                  <Link
                    to="/CloudServices"
                    className="btn-styles btn btn-info"
                    onClick={scrollToTop}>
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-4 d-flex">
            <br />
            <div className="card flex-fill card-hover-effect">
              <img
                src={youroffer2}
                className="card-img-top"
                alt="responsiveImage"
                style={{ height: "200px" }}
              />
              <div className="card-body text-center">
                <h5 className="card-title">Database Services</h5>
                <p className="card-text">
                  Over a decade of experience and implementing best solutions in
                  Database Technologies and Web Development as we do DB Entire
                  Life Cylcle Support from database development to DBA support.
                </p>
                <br />
                <br />

                <div className="d-flex justify-content-center align-items-center databtn">
                  <Link
                    to="/DatabaseServices"
                    className="btn-styles btn btn-info"
                    onClick={scrollToTop}>
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-4 d-flex">
            <br />
            <div className="card flex-fill card-hover-effect">
              <img
                src={youroffer4}
                className="card-img-top"
                alt="responsiveImage"
                style={{ height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title">Additional Services</h5>
                <p className="card-text">
                  We provide a dverse range of Additional services to meet
                  specific needs and challenges.
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />

                <div className="d-flex justify-content-center align-items-center Addbtn">
                  <Link
                    to="/AdditionalServices"
                    className="btn-styles btn btn-info"
                    onClick={scrollToTop}>
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <h3
          style={{ fontFamily: "rockwell" }}
          className="mt-4 mb-3 Text-Gradient">
          <b>
            For a reliable and professional technology partner, choose CLOTA
            TECHNOLOGY.
          </b>
        </h3>
      </div>
      <img
        src={bottomBanner}
        style={{ height: "350px", width: "100%" }}
        alt="Responsive"
      />
    </>
  );
}

export default About;
